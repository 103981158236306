import React from 'react';
import styled from 'styled-components'
import Signup from '../../../organisms/management/staff/Signup'

export const New = () => {
  return (
    <NewArea>
      <Signup />
    </NewArea>
  )
}

const NewArea = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export default New
