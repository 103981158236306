import React, { useState, useEffect } from 'react';
import styled from 'styled-components'
import TextForm from '../../../atoms/TextForm'
import Info from '../../../atoms/share/Info'
import Error from '../../../atoms/share/Error'
import SubmitButton from '../../../atoms/SubmitButton'
import { createDepartment, updateDepartment } from '../../../../api/department'

export interface Props {
  department?: {
    id: number
    name: string
  }
}

export const DepartmentForm: React.FC<Props> = ({ department }) => {
  const [id, setId] = useState(null)
  const [name, setName] = useState('')
  const [info, setInfo] = useState('')
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (department !== undefined) {
      setId(department.id)
      setName(department.name)
    }
  }, []);

  const handleSubmit = async() => {
    setLoading(true)
    if (document.location.pathname === '/management/departments/new') {
      await createDepartment(name)
        .then(() => {
          document.location.href = '/management/staff'
        })
        .catch(() => {
          setError('登録内容に不備があります。')
        })
    } else {
      await updateDepartment(id, name)
        .then(() => {
          setInfo('更新しました')
        })
        .catch(() => {
          setError('登録内容に不備があります。')
        })
    }
    setLoading(false)
  }

  const infoMessage = () => {
    if (info !== "") {
      return <Info>{info}</Info>
    }
  }

  const errorMessage = () => {
    if (error !== "") {
      return <Error>{error}</Error>
    }
  }

  return (
    <DepartmentFormArea>
      <Notice>{infoMessage()}{errorMessage()}</Notice>
      <Item>
        <Label>部署名</Label>
        <NameForm>
          <TextForm type="text" placeholder="例）営業部、東京店 など" value={name} onChange={(e) => setName(e.target.value)} />
        </NameForm>
      </Item>
      <ButtonBox>
        <SubmitButton
          text={document.location.pathname === '/management/departments/new' ? '新規登録する' : '変更を適用する'}
          onClick={handleSubmit}
          disabled={!name || loading}
        />
      </ButtonBox>
    </DepartmentFormArea>
  )
}

const DepartmentFormArea = styled.form``
const Notice = styled.div`
  text-align: center;
`
const Item = styled.div`
  display: flex;
  align-items: center;
  line-height: 43px;
  list-style: none;
  margin-bottom: 24px;
  column-gap: 3rem;
`
const Label = styled.label`
  color: #000000;
  text-align:  right;
  font-size: 16px;
  width: 10rem;
`
const NameForm = styled.div`
  width: 70%;
`
const ButtonBox = styled.div`
  width: 100%;
  margin: 75px auto 0;
  text-align: center;
`

export default DepartmentForm
