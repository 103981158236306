import React from 'react';
import styled from 'styled-components'

export const Index = () => {
  return (
    <div>
      aaa
    </div>
  )
}

export default Index
