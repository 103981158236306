import React, { useState } from 'react';
import styled from 'styled-components'
import Select from 'react-select'
import Title from '../../../atoms/share/Title'
import SubmitButton from '../../../atoms/SubmitButton'
import TextAreaForm from '../../../atoms/share/TextAreaForm'
import Info from '../../../atoms/share/Info'
import Error from '../../../atoms/share/Error'
import { years, months, days } from '../../../../modules/date'
import { createHolidayRequest } from '../../../../api/holiday_request'
import ButtonSub from '../../../atoms/share/button/ButtonSub';

const kinds = [
  { value: 0, label: '有給' },
  { value: 1, label: '代休' }, 
  { value: 2, label: '振替休日'}
]

export const Holiday = () => {
  const [kind, setKind] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [selectedDay, setSelectedDay] = useState(null);
  const [reason, setReason] = useState('');
  const [loading, setLoading] = useState(false);
  const [info, setInfo] = useState('');
  const [error, setError] = useState('');

  const initialFormState = () => {
    setKind('')
    setSelectedYear(null)
    setSelectedMonth(null)
    setSelectedDay(null)
    setReason('')
  }

  const handleSubmit = () => {
    setInfo('')
    setError('')
    setLoading(true)
    createHolidayRequest(Number(kind.value), selectedYear.value, selectedMonth.value, selectedDay.value, reason)
      .then(() => {
        setInfo('申請が完了しました。')
        initialFormState()
      })
      .catch(() => {
        setError('申請内容に不備があります。')
      })
    setLoading(false)
  }

  const infoMessage = () => {
    if (info !== "") {
      return <Info>{info}</Info>
    }
  }

  const errorMessage = () => {
    if (error !== "") {
      return <Error>{error}</Error>
    }
  }

  return (
    <HolidayArea>
      <Title value='新規休暇申請' />
      <Notice>{infoMessage()}{errorMessage()}</Notice>
      <HolidayForm>
        <Item>
          <Label>休暇種類</Label>
          <SelectBox><Select value={kind} onChange={setKind} options={kinds} styles={SelectArea} placeholder="選択してください" /></SelectBox>
        </Item>
        <Item>
          <Label>休暇希望日</Label>
          <SelectBox><Select value={selectedYear} onChange={setSelectedYear} options={years} />年</SelectBox>
          <SelectBox><Select value={selectedMonth} onChange={setSelectedMonth} options={months} />月</SelectBox>
          <SelectBox><Select value={selectedDay} onChange={setSelectedDay} options={days} />日</SelectBox>
        </Item>
        <Item>
          <Label>休暇理由</Label>
          <TextAreaBox>
            <TextAreaForm value={reason} onChange={(e) => setReason(e.target.value)} />
          </TextAreaBox>
        </Item>
      </HolidayForm>
      <SubmitButton
        text="申請する"
        onClick={handleSubmit}
        disabled={!kind || !selectedYear || !selectedMonth || !selectedDay || loading}
      />
      <ButtonArea>
        <ButtonSub path={`/employees/requests`} text='戻る' />
      </ButtonArea>
    </HolidayArea>
  )
}

const HolidayArea = styled.div`
  width: 70%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3rem;
  background-color: #ffffff;
  border-radius: 1rem;
  font-size: 16px;
  padding: 3rem 0;
`
const HolidayForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`
const Notice = styled.div`
  text-align: center;
`
const Item = styled.div`
  display: flex;
  list-style: none;
`
const Label = styled.label`
  color: #000000;
  text-align: right;
  width: 5rem;
  margin: auto 3rem auto 0;
`
const SelectBox = styled.div`
  display: flex;
  align-items: baseline;
  margin: 0 5px;
`
const SelectArea = {
  control: (provided) => ({
    ...provided,
    width: '15rem',
  }),
};
const TextAreaBox = styled.div`
  width: 430px;
`
const ButtonArea = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

export default Holiday
