import React, { useState, useEffect } from 'react';
import Select from 'react-select'
import styled from 'styled-components'
import TableHead from '../../atoms/share/TableHead'
import TableBody from '../../atoms/share/TableBody'
import { years, months } from '../../../modules/date'
import { fetchEmployeeInfo } from '../../../api/employee'
import { fetchTimestamps, deleteTimestamp } from '../../../api/timestamp'
import { EmployeeInfo } from '../../../types/employee'
import ButtonRed from '../../atoms/share/button/ButtonRed';
import ButtonSecondary from '../../atoms/share/button/ButtonSecondary';

export const ScheduleTable = () => {
  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [employee, setEmployee] = useState<EmployeeInfo>({
    id: null, name: '', cell: '', birthday: '', email: '', department_id: null, department_name: '', employment_status_id: null, employment_status_name: ''
  });
  const [timestamps, setTimestamps] = useState([]);

  useEffect(() => {
    fetchEmployeeInfo()
      .then((res) => {
        setEmployee(res.data)
      })
      .catch(() => { window.alert('通信に失敗しました')})
    fetchTimestamps()
      .then((res) => { setTimestamps(res.data) })
      .catch(() => { window.alert('通信に失敗しました')})
  }, []);

  const heads = ['日付', '出勤時刻', '退勤時刻', '備考・メモ', '']

  const handleSubmit = () => {
    fetchTimestamps(null, selectedYear.value, selectedMonth.value)
      .then((res) => {
        setTimestamps(res.data)
      })
      .catch(() => {
        window.alert('通信に失敗しました')
      })
  }

  const handleDelete = (id: number) => {
    if (id === null) {
      return;
    }
    deleteTimestamp(id)
      .then((res) => {
        fetchTimestamps(res.data.employee_id)
          .then((res) => { setTimestamps(res.data) })
      })
      .catch(() => {
        window.alert('通信に失敗しました')
      });
  }

  return (
    <ScheduleTableArea>
      <Top>
        <Section>
          <SelectBox><Select value={selectedYear} onChange={setSelectedYear} options={years} />年</SelectBox>
          <SelectBox><Select value={selectedMonth} onChange={setSelectedMonth} options={months} />月</SelectBox>
          <ButtonBox>
            <ButtonSecondary text='表示する' onClick={handleSubmit} disabled={ !selectedYear || !selectedMonth } />
          </ButtonBox>
        </Section>
      </Top>
      <Table>
        <TableHead items={heads} />
        <Tbody>
          {timestamps.map((timestamp, index) => (
            <Row key={index} color={timestamp.is_holiday || timestamp.is_paid_leave ? "#f8bebc" : "#ffffff"}>
              <TableBody>{timestamp.date}</TableBody>
              <TableBody>{timestamp.punch_in_at}</TableBody>
              <TableBody>{timestamp.punch_out_at}</TableBody>
              <TableBody>{timestamp.note}</TableBody>
              <TableBody>
                {timestamp.id !== null && (
                  <TableButtonArea>
                    <ButtonRed text='削除' onClick={() => handleDelete(timestamp.id)} />
                  </TableButtonArea>
                )}
              </TableBody>
            </Row>
          ))}
        </Tbody>
      </Table>
    </ScheduleTableArea>
  )
}

const ScheduleTableArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
const Top = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  width: 80%;
  margin-bottom: 24px;
`
const Section = styled.div`
  align-items: center;
  display: flex;
`
const SelectBox = styled.div`
  display: flex;
  align-items: center;
  margin-right: 8px;
  font-size: 16px;
`
const ButtonBox = styled.div`
  margin-left: 50px;
`
const Table = styled.table`
  border-collapse: collapse;
  width: 80%;
  table-layout: fixed;
`
const Tbody = styled.tbody`
  display: contents;
  overflow-x: hidden;
  overflow-y: scroll;
  height: 500px;
`
const Row = styled.tr`
  border-bottom: 1px solid #6b7280;
  background-color: ${(props) => props.color};
`
const TableButtonArea = styled.span`
  display: block;
  text-align: center;
`

export default ScheduleTable
