import React from 'react';
import styled from 'styled-components'

export interface Props {
  path: string;
  text: string;
}

export const ButtonPrimary: React.FC<Props> = ({ path, text }) => {
  return <ButtonArea href={path}>{text}</ButtonArea>
}

const ButtonArea = styled.a`
  color: #ffffff;
  background: #008000;
  border: 1px solid #006400;
  border-radius: 6px;
  text-align: center;
  cursor: pointer;
  width: 90px;
  height: 25px;
  font-size: 15px;
  white-space: nowrap;
  margin: auto 20px;
  &:hover {
    color: #000000;
    background-color:transparent;
  }
`

export default ButtonPrimary
