import React from 'react'
import styled from 'styled-components'

export interface RadioButtonProps {
  text: string;
  name: string;
  value: string
  onChange: any;
}

export const TextForm: React.FC<RadioButtonProps> = ({ text, name, value, onChange }) => {
  return (
    <RadioButtonArea>
      <Input type='radio' name={name} value={value} onChange={onChange} />
      <Label>{text}</Label>
    </RadioButtonArea>
  )
}

const RadioButtonArea = styled.div`
  display: flex;
  font-weight: bold;
`
const Label = styled.label`
  margin-left: 10px;
`
const Input = styled.input`
  width: 20px;
  height: 20px;
  margin: auto;
`

export default TextForm
