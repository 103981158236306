import client from './client'

export const fetchModifyTimestamps = () => {
  return client.get('/api/v1/modify_timestamps')
}

export const fetchModifyTimestamp = (year: string, month: string, day: string) => {
  return client.get(
    '/api/v1/modify_timestamp',
    {
      params: {
        year: year,
        month: month,
        day: day
      }
    }
  )
}

export const createModifyTimestamps = (id: number, year: string, month: string, day: string, punch_in_at: number, punch_out_at: number, reason: string) => {
  return client.post(
    '/api/v1/modify_timestamps',
    {
      id: id,
      year: year,
      month: month,
      day: day,
      punch_in_at: punch_in_at,
      punch_out_at: punch_out_at,
      reason: reason
    }
  )
}

// export const deleteModifyTimestamp = (id: number) => {
//   return client.delete(`/api/v1/modify_timestamps/${id}`)
// }
