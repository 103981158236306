import React from 'react'
import styled from 'styled-components'
import TopBanner from 'images/home/top_banner_2-100.jpg'

export const Top = () => {
  return (
    <TopArea>
      <Img src={TopBanner} />
      <SubContent>
        <Title>勤怠管理をスマートに</Title>
        <SubTitle>Make the attendance management smarted</SubTitle>
        <SubText>スマート勤怠 EXtは勤怠管理業務をスマートにDXし、<br />業務効率UPを推進します</SubText>
      </SubContent>
    </TopArea>
  )
}

const TopArea = styled.div`
  margin: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
`
const Img = styled.img`
  width: 100%;
`
const SubContent = styled.div`
  width: fit-content;
  position: absolute;
  top: 23rem;
  left: 20rem;
  z-index: 50;
  @media screen and (max-width: 1000px) {
    top: 9rem;
    left: 7rem;
  }
`
const Title = styled.div`
  font-size: 3.7rem;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: bold;
  color: #ffffff;
  width: fit-content;
`
const SubTitle = styled.div`
  color: #ffffff;
  font-size: 1.3rem;
  font-family: 'Michroma', sans-serif, 'Noto Sans JP';
  width: fit-content;
`
const SubText = styled.div`
  color: #ffffff;
  font-size: 1.5rem;
  font-weight: bold;
  margin-top: 5rem;
  width: fit-content;
`
export default Top
