import client from './client'

// 下記でいけるのでは
// https://qiita.com/shate/items/bd52e1a09c30f1bff4ca

// export interface createCompanyParams {
//   name: string,
//   staffName: string,
//   email: string,
//   cell: string,
//   password: string,
//   plan: number,
//   hasOption: boolean
// }

// ここpropsにinterface渡せるか確認
export const createCompany = (name: string, staff_name: string, email: string, cell: string, password: string, plan: number, has_option: boolean) => {
  return client.post('/companies', { name, staff_name, email, cell, password, plan, has_option })
}

export const loginCompany = (email: string, password: string) => {
  return client.post('/companies/sign_in', { company: { email: email, password: password } })
}

export const signOutCompany = () => {
  return client.delete('/companies/sign_out')
}
