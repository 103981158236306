import React from 'react'
import styled from 'styled-components'
import option1 from 'images/home/plan/option_1.png'
import option2 from 'images/home/plan/option_2.png'
import option3 from 'images/home/plan/option_3.png'

const Plan = () => {
  return (
    <PlanArea>
      <Title>料金プラン</Title>
      <SubTitle>Price</SubTitle>
      <List>
        <PlanGrid>
          <PlanGridHead>プラン名</PlanGridHead>
          <PlanGridHead>作成可能アカウント数</PlanGridHead>
          <PlanGridHead>利用可能機能</PlanGridHead>
          <PlanGridHead>オプション相談</PlanGridHead>
          <PlanGridHead>価格</PlanGridHead>
          <PlanGridDiv><span>1ヶ月プラン</span></PlanGridDiv>
          <PlanGridDiv>無制限</PlanGridDiv>
          <PlanGridDiv>全機能</PlanGridDiv>
          <PlanGridDiv>可</PlanGridDiv>
          <PlanGridPriceDiv><span>30</span>万円</PlanGridPriceDiv>
          <PlanGridMonthlyTitleDiv><span>6ヶ月プラン</span></PlanGridMonthlyTitleDiv>
          <PlanGridDiv>無制限</PlanGridDiv>
          <PlanGridDiv>全機能</PlanGridDiv>
          <PlanGridDiv>可</PlanGridDiv>
          <PlanGridPriceDiv><span>150</span>万円</PlanGridPriceDiv>
          <PlanGridYearlyDiv>
            <PlanGridYearlyTitleDiv><span>1年プラン</span></PlanGridYearlyTitleDiv>
            <PlanGridDiv>無制限</PlanGridDiv>
            <PlanGridDiv>全機能</PlanGridDiv>
            <PlanGridDiv>可</PlanGridDiv>
            <PlanGridPriceDiv><span>240</span>万円</PlanGridPriceDiv>
          </PlanGridYearlyDiv>
        </PlanGrid>
      </List>
      <Description>
        ※上記プランは税抜価格です<br />
        ※当社サービスは自動更新となっています
      </Description>
      <Options>
        <Title>オプション</Title>
        <SubTitle>Options</SubTitle>
        <List>
          <Item>
            <ItemImgArea>
              <ItemImg src={option1}></ItemImg>
            </ItemImgArea>
            <ItemTextArea>
              <ItemTextTitle>導入サポート</ItemTextTitle>
              <ItemTextDescription>
                ご契約後の導入方法や各種お手続き等、専属スタッフがフルサポートいたします。
              </ItemTextDescription>
              <ItemTextPrice><span>40</span>万円 ~</ItemTextPrice>
            </ItemTextArea>
          </Item>
          <Item>
            <ItemImgArea>
              <ItemImg src={option2}></ItemImg>
            </ItemImgArea>
            <ItemTextArea>
              <ItemTextTitle>コンサルティング</ItemTextTitle>
              <ItemTextDescription>
                当サービス以外でも、ITに関するご相談、DXできそうな業務提案等、貴社に最適なコンサルティングをご提供いたします。
              </ItemTextDescription>
              <ItemTextPrice><span>45</span>万円 ~</ItemTextPrice>
            </ItemTextArea>
          </Item>
          <Item>
            <ItemImgArea>
              <ItemImg src={option3}></ItemImg>
            </ItemImgArea>
            <ItemTextArea>
              <ItemTextTitle>社内運用マニュアル作成</ItemTextTitle>
              <ItemTextDescription>
                当サービスの社内周知用専用マニュアルや、研修講座等承っております。
              </ItemTextDescription>
              <ItemTextPrice><span>35</span>万円 ~</ItemTextPrice>
            </ItemTextArea>
          </Item>
        </List>
        <Description>
          ※上記は税抜価格です
        </Description>
      </Options>
    </PlanArea>
  )
}

const PlanArea = styled.div`
  background: #ffffff;
  padding: 50px 150px;
  margin: 10px 0;
  @media screen and (max-width: 1000px) {
    padding: 0;
  }
`
const Title = styled.div`
  color: #000000;
  font-size: 2.5rem;
  font-weight: bold;
  text-align: center;
`
const SubTitle = styled.div`
  color: #c3c3c3;
  font-size: 2rem;
  font-weight: bold;
  font-family: 'Michroma', sans-serif, 'Noto Sans JP';
  text-align: center;
  margin-bottom: 4rem;
`
const PlanGrid = styled.div`
  background-color: #ffffff;
  display: grid;
  grid-template-columns: repeat(5, minmax(0, 1fr));
`
const PlanGridHead = styled.div`
  grid-column: span 1 / span 1;
  text-align: center;
  font-size: 1rem;
  font-weight: bold;
`
const PlanGridDiv = styled.div`
  grid-column: span 1 / span 1;
  text-align: center;
  font-size: 1.5rem;
  padding: 1.2rem 1rem;

  > span {
    font-weight: bold;
  }
  @media screen and (max-width: 1000px) {
    font-size: 1.2rem;
  }
`
const PlanGridMonthlyTitleDiv = styled.div`
  grid-column: span 1 / span 1;
  text-align: center;
  font-size: 1.5rem;
  padding: 1.2rem 1rem;

  > span {
    font-weight: bold;
    background: linear-gradient(transparent 60%, yellow 30%);
  }
  @media screen and (max-width: 1000px) {
    font-size: 1.2rem;
  }
`
const PlanGridYearlyTitleDiv = styled.div`
  grid-column: span 1 / span 1;
  text-align: center;
  font-size: 1.5rem;
  padding: 1.2rem 1rem;

  > span {
    font-weight: bold;
    background: linear-gradient(transparent 60%, #ff69b4 30%);
  }
  @media screen and (max-width: 1000px) {
    font-size: 1.2rem;
  }
`
const PlanGridPriceDiv = styled.div`
  grid-column: span 1 / span 1;
  text-align: center;
  font-size: 1.5rem;
  padding: 1.2rem 1rem;

  > span {
    font-size: 2rem;
    font-weight: bold;
  }
  @media screen and (max-width: 1000px) {
    font-size: 1.2rem;
    > span {
      font-size: 1.5rem;
    }
  }
`
const PlanGridYearlyDiv = styled.div`
  grid-column: 1 / -1;
  display: grid;
  grid-template-columns: repeat(5, minmax(0, 1fr));
  border: 2px solid #ffa500;
`
const Description = styled.p`
  color: #707071;
  font-size: 12px;
  margin: 10px 0 0;
  padding-left: 10rem;
`
const Options = styled.div`
  margin-top: 80px;
  background-color: transparent;
`
const List = styled.div`
  width: 75%;
  margin: auto;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  padding: 3rem;
  border-radius: 1rem;
  box-shadow: 0 0 1rem  rgba(0,0,0,0.3);
`
const Item = styled.div`
  display: flex;
  border-radius: 1rem;
  box-shadow: 0 0 1rem  rgba(0,0,0,0.3);
  margin: 1rem 0;
  padding: 1rem;
`
const ItemImgArea = styled.div`
  width: 40%;
  display: flex;
`
const ItemImg = styled.img`
  height: 15vh;
  margin: auto;
  @media screen and (max-width: 1000px) {
    height: 10vh;
  }
`
const ItemTextArea = styled.div`
  width: 60%;
  display: flex;
  flex-direction: column;
  padding: 1rem;
`
const ItemTextTitle = styled.div`
  font-size: 1.5rem;
  font-weight: bold;
  border-bottom: 1px solid #707071;
  @media screen and (max-width: 1000px) {
    font-size: 1.2rem;
  }
`
const ItemTextDescription = styled.p`
  font-size: 1rem;
`
const ItemTextPrice = styled.div`
  width: 100%;
  text-align: right;
  font-size: 1.5rem;

  >span {
    font-size: 2rem;
    font-weight: bold;
  }
`
export default Plan
