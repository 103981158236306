import React from 'react'
import styled from 'styled-components'
import LogoImg from 'images/share/logoWhite.svg'
import LogoWhite from '../../molecules/share/LogoWhite'

// import { PrimaryButton } from 'smarthr-ui'
// <PrimaryButton>Hello World</PrimaryButton>

export const Footer = () => {
  return (
    <FooterArea>
      <LogoArea>
        <LogoWhite />
        <Text>
          ©︎ 2024 ext Inc.
        </Text>
      </LogoArea>
      <CompanyArea>
        <Middle href='https://ext.cx/' target={'_blank'} rel='no-referrer'>
          <Img src={LogoImg} />
        </Middle>
      </CompanyArea>
    </FooterArea>
  )
}

const FooterArea = styled.footer`
  height: 3rem;
  background-color: #000000;
  padding: 1rem 2rem;
  display: flex;
  justify-content: space-between;
`
const LogoArea = styled.div`
  width: 60%;
  display: flex;
`
const CompanyArea = styled.div`
  width: 40%;
  display: flex;
  justify-content: right;
  margin: auto 0;
  padding-right: 5rem;
`
const Text = styled.div`
  margin: auto 2rem;
`
const Middle = styled.a`
`
const Img = styled.img`
  width: 10rem;
`

export default Footer
