import React from 'react';
import styled from 'styled-components';
import Content from '../../organisms/management/Content';

export const Index = () => {
  return (
    <IndexArea>
      <Content />
    </IndexArea>
  )
};

const IndexArea = styled.div``;

export default Index
