import client from './client'

export const createBreakTime = (conditionHour: number, conditionMinute: number, breakHour: number, breakMinute: number, departmentId?: number, employmentStatusId?: number) => {
  return client.post('/api/v1/break_times', {
    condition_hour: conditionHour,
    condition_minute: conditionMinute,
    break_hour: breakHour,
    break_minute: breakMinute,
    department_id: departmentId,
    employment_status_id: employmentStatusId
  })
}