import React from "react";
import styled from "styled-components";

export interface SubmitButtonProps {
  onClick: any;
  text: string;
  disabled?: boolean;
}

export const SubmitButton: React.FC<SubmitButtonProps> = ({ onClick, text, disabled = false }) => {
  return <BaseButton type="submit" onClick={onClick} disabled={disabled} >{text}</BaseButton>;
};

const BaseButton = styled.button`
  color: #ffffff;
  text-decoration: none;
  background: linear-gradient(90deg, #37a179 0%, #0c3d7e 100%);
  border-radius: 2.7rem;
  padding: 0.3rem 1rem;
  cursor: pointer;
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
  letter-spacing: 0.1rem;
  border: 0;
  &:hover {
    color: #ece9e9e6;
  }
  &:disabled {
    color: #ffffff;
    background: #9BA4B4;
    pointer-events: none;
  }
`;

export default SubmitButton
