import React, { useState, useEffect } from 'react';
import styled from 'styled-components'
import Select from 'react-select'
import TextForm from '../../atoms/TextForm'
import SubmitButton from '../../atoms/SubmitButton'
import Info from '../../atoms/share/Info'
import Error from '../../atoms/share/Error'
import { fetchModifyTimestamp, createModifyTimestamps } from '../../../api/modify_timestamp'
import { years, months, days } from '../../../modules/date'
import ButtonSecondary from '../../atoms/share/button/ButtonSecondary';

const year = []
const now = new Date
for (let i = 2022; i <= now.getFullYear(); i++) {
  year.push({ value: i, label: i })
}
const month = []
for (let i = 1; i <= 12; i++) {
  month.push({ value: i, label: i })
}
const day = []
for (let i = 1; i <= 31; i++) {
  day.push({ value: i, label: i })
}

export const ModifyTimestampForm = () => {
  const [timestampId, setTimestampId] = useState(null)
  const [currentPunchInAt, setCurrentPunchInAt] = useState('打刻なし')
  const [currentPunchOutAt, setCurrentPunchOutAt] = useState('打刻なし')
  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [selectedDay, setSelectedDay] = useState(null);
  const [punchInAt, setPunchInAt] = useState(null)
  const [punchOutAt, setPunchOutAt] = useState(null)
  const [reason, setReason] = useState('')
  const [message, setMessage] = useState('')
  const [error, setError] = useState('');


  const showModifyTimestamp = () => {
    setMessage('')
    setError('')
    fetchModifyTimestamp(selectedYear, selectedMonth, selectedDay)
      .then((res) => {
        setTimestampId(res.data.id)
        setCurrentPunchInAt(res.data.punch_in_at)
        setCurrentPunchOutAt(res.data.punch_out_at)
      })
      .catch((res) => {
        if (res.response.data.message) {
          setError(res.response.data.message)
        } else {
          window.alert('通信に失敗しました')
        }
      })
  }

  const handleSubmit = () => {
    setMessage('')
    setError('')
    createModifyTimestamps(timestampId, selectedYear, selectedMonth, selectedDay, punchInAt, punchOutAt, reason)
      .then((res) => {
        setMessage('打刻修正を完了しました。')
        setPunchInAt('')
        setPunchOutAt('')
      })
      .catch(() => {
        setError('正しい時刻を設定してください。')
      })
  }

  const infoMessage = () => {
    if (message !== "") {
      return <Info>{message}</Info>
    }
  }

  const errorMessage = () => {
    if (error !== "") {
      return <Error>{error}</Error>
    }
  }

  return (
    <ModifyTimestampFormArea>
      <SelectSection>
        <SelectBox><Select value={selectedYear} onChange={setSelectedYear} options={years} />年</SelectBox>
        <SelectBox><Select value={selectedMonth} onChange={setSelectedMonth} options={months} />月</SelectBox>
        <SelectBox><Select value={selectedDay} onChange={setSelectedDay} options={days} />日</SelectBox>
        <ButtonBox><ButtonSecondary text='表示' onClick={showModifyTimestamp} disabled={!selectedYear || !selectedMonth || !selectedDay} /></ButtonBox>
      </SelectSection>
      <Notice>{infoMessage()}{errorMessage()}</Notice>
      <FormSection>
        <Wrapper>
          <Timestamp>
            <BeforeModify>出勤時刻：{currentPunchInAt}</BeforeModify>
            <BeforeModify>退勤時刻：{currentPunchOutAt}</BeforeModify>
          </Timestamp>
          <Arrow>→</Arrow>
          <ModifyTimestamp>
            <AfterModify>
              <Label>出勤時刻</Label>
              <TextForm type="number" value={punchInAt} onChange={(e) => setPunchInAt(e.target.value)} />
            </AfterModify>
            <AfterModify>
              <Label>退勤時刻</Label>
              <TextForm type="number" value={punchOutAt} onChange={(e) => setPunchOutAt(e.target.value)} />
            </AfterModify>
            <AfterModify>
              <Label>修正理由</Label>
              <TextForm type="text" value={reason} onChange={(e) => setReason(e.target.value)} />
            </AfterModify>
          </ModifyTimestamp>
        </Wrapper>
        <Submit>
          <SubmitButton
            text='打刻修正する'
            onClick={handleSubmit}
            disabled={!punchInAt && !punchOutAt}
          />
        </Submit>
      </FormSection>
    </ModifyTimestampFormArea>
  )
}

const ModifyTimestampFormArea = styled.div`
  font-size: 16ox;
`
const SelectSection = styled.div`
  display flex;
  margin-bottom: 75px;
  width: 100%;
`
const SelectBox = styled.div`
  height: 40px;
  margin: 0 5px;
  display: flex;
  align-items: center;
  font-size: 16px;
`
const ButtonBox = styled.div`
  height: 20px;
  width: 20%;
  margin: 0 0 0 50px;
  text-align: end;
`
const Notice = styled.div`
  text-align: center;
`
const FormSection = styled.div``
const Wrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  margin: 0 0 0 30px;
`
const Timestamp = styled.div`
  margin: auto 0;
`
const ModifyTimestamp = styled.div`
  width: 470px;
`
const BeforeModify = styled.div`
  align-items: center;
  display: flex;
  font-size: 16px;
  margin: 12px 0;
`
const Arrow = styled.div`
  margin: 0 30px 0 75px;
  display: flex;
  align-items: center;
`
const AfterModify = styled.div`
  align-items: center;
  display: flex;
  font-size: 16px;
  margin-bottom: 24px;
`
const Label = styled.label`
  text-align: center;
  width: 100%
`
const Submit = styled.div`
  margin: 50px 0 100px 0;
  width: 100%;
  text-align: center;
`


export default ModifyTimestampForm
