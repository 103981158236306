import React from 'react';
import styled from 'styled-components'

export interface Props {
  value: string
}

export const Title: React.FC<Props> = ({ value }) => {
  return <TitleArea>{value}</TitleArea>
}

const TitleArea = styled.div`
  font-size: 20px;
  font-weight: bold;
`

export default Title
