import React, { useState } from 'react';
import styled from 'styled-components'

export interface Props {
  title: string
  items?: Array<ItemProps>
}

export interface ItemProps {
  name: string
  path: string
}

export const SideItem: React.FC<Props> = ({ title, items }) => {
  const [closed, setClosed] = useState(true)

  const toggleList = () => {
    setClosed(!closed)
  }

  if (closed) {
    return (
      <SideItemArea>
        <Title onClick={toggleList}>{title}</Title>
      </SideItemArea>
    )
  } else {
    return (
      <SideItemArea>
        <Title onClick={toggleList}>{title}</Title>
        <List>
          {items.map(item => (
            <Item>
              <Link href={item.path}>
                {item.name}
              </Link>
            </Item>
          ))}
        </List>
      </SideItemArea>
    )
  }
}

const SideItemArea = styled.div`
  letter-spacing: 1px;
`
const Title = styled.div`
  cursor: pointer;
`
const List = styled.ul`
  cursor: pointer;
  list-style-type: none;
  padding: 0 0 0 1rem;
  line-height: 2rem;
  font-weight: normal;
  margin: 0 0;
`
const Item = styled.li`
`
const Link = styled.a`
  color: #000000;
  text-decoration: none;
`

export default SideItem
