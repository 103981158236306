import client from './client'

export const fetchOverWorkRequests = (year?: string, month?: string) => {
  return client.get('/api/v1/over_work_requests', { params: { year: year, month: month } })
}

export const createOverWorkRequest = (year: number, month: number, day: number, reason: string) => {
  return client.post('/api/v1/over_work_requests', { year: year, month: month, day: day, reason: reason })
}

export const deleteOverWorkRequest = (id: number) => {
  return client.delete(`/api/v1/over_work_requests/${id}`)
}

export const updateOverWorkRequest = (id: number, status: number) => {
  return client.put(`/api/v1/over_work_requests/${id}`, { status: status })
}
