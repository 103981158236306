import React from 'react';
import styled from 'styled-components';
import Title from '../../atoms/share/Title'
import ScheduleTable from '../../organisms/management/ScheduleTable'

export interface Props {
  id: number;
}

export const Schedule: React.FC<Props> = ({ id }) => {
  return (
    <ScheduleArea>
      <TitleArea>
        <Title value='出勤表' />
      </TitleArea>
      <ScheduleTable id={id} />
    </ScheduleArea>
  )
};

const ScheduleArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  background-color: #ffffff;
  border-radius: 1rem;
  padding: 2rem;
`
const TitleArea = styled.div`
  width: 100%;
  text-align: center;
`

export default Schedule
