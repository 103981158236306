const now = new Date

export const years = []
for (let i = 2022; i <= now.getFullYear(); i++) {
  years.push({ value: i, label: i })
}

export const months = []
for (let i = 1; i <= 12; i++) {
  months.push({ value: i, label: i })
}


export const days = []
for (let i = 1; i <= 31; i++) {
  days.push({ value: i, label: i })
}
