import client from './client'

export const fetchDepartments = () => {
  return client.get('/api/v1/departments')
}

export const createDepartment = (name: string) => {
  return client.post('/api/v1/departments', { name: name })
}

export const updateDepartment = (id: number, name: string) => {
  return client.put(`/api/v1/departments/${id}`, { name: name })
}

export const deleteDepartment = (id: number) => {
  return client.delete(`/api/v1/departments/${id}`)
}
