import client from './client'

export const fetchEmployees = () => {
  return client.get('/api/v1/employees')
}

export const createEmployee = (last_name: string, first_name: string, email: string, password: string, departmentId?: number, employmentStatusId?: number) => {
  return client.post(
    '/api/v1/employees',
    {
      last_name: last_name,
      first_name: first_name,
      email: email,
      password: password,
      department_id: departmentId,
      employment_status_id: employmentStatusId
    }
  )
}

export const fetchEmployeeInfo = (id?: number) => {
  return client.get('/api/v1/employees/info', { params: { id: id } })
}

export const updateCell = (cell: number) => {
  return client.put('/api/v1/employees/update_cell', { cell })
}

interface updateEmployeeParams {
  last_name: string;
  first_name: string;
  email: string;
  password: string;
  department_id?: number;
  employment_status_id?: number;
}

export const updateEmployee = (id: number, params: updateEmployeeParams) => {
  return client.put(`/api/v1/employees/${id}`, params)
}
export const deleteEmployee = (id: number) => {
  return client.delete(`/api/v1/employees/${id}`)
}
