import React from 'react';
import styled from 'styled-components'

export const Error: React.FC = ({ children }) => {
  return (
    <ErrorMessage>
      {children}
    </ErrorMessage>
  )
}

const ErrorMessage = styled.p`
  color: #FF0000;
  font-weight: bold;
  padding: 10px 0;
`

export default Error
