import React from 'react'
import styled from 'styled-components'

export interface TextFormProps {
  type: string;
  placeholder?: string;
  value: string;
  onChange: any;
}

export const TextForm: React.FC<TextFormProps> = ({ type, placeholder = '', value, onChange }) => {
  return (
    <Input type={type} placeholder={placeholder} value={value} onChange={onChange} />
  )
}

const Input = styled.input`
  flex-grow: 1;
  display: block;
  width: 80%;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: calc(24px + 0px);
  padding-right: calc(24px + 0px);
  border: 1px solid #000000;
  border-radius: 1rem;
  font-size: 16px;
  color: #23221f;
  line-height: 1.6;
  box-sizing: border-box;
  background-color: #ffffffff;
`

export default TextForm
