import React from 'react'
import styled from 'styled-components'
import Logo from '../../molecules/share/Logo'

export const Header = () => {
  return (
    <HeaderArea>
      <LogoArea>
        <Logo />
      </LogoArea>
    </HeaderArea>
  )
}

const HeaderArea = styled.header`
  width: 100%;
  height: 80px;
`
const LogoArea = styled.div`
  display: flex;
  padding: 20px 80px;
  background-color:  rgba(255, 255, 255, 0.5);
`

export default Header
