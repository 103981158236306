import React from 'react';
import styled from 'styled-components'

export interface Props {
  title: string,
  totalName: string,
  totalTime: string,
  averageName: string,
  averageTime: string,
}

export const StatusTable: React.FC<Props> = ({ title, totalName, totalTime, averageName, averageTime }) => {
  return (
    <StatusTableArea>
      <Row>
        <Thead>{title}</Thead>
      </Row>
      <Row>
        <TitleTbody>{totalName}</TitleTbody>
        <Tbody>{totalTime}時間</Tbody>
      </Row>
      <Row>
        <TitleTbody>{averageName}</TitleTbody>
        <Tbody>{averageTime}時間</Tbody>
      </Row>
    </StatusTableArea>
  )
}

const StatusTableArea = styled.table`
  font-size: 14px;
  width: 100%;
`
const Row = styled.tr``
const Thead = styled.th.attrs({ colSpan: 2 })`
  background-color: #ecf0f2;
  color: #000000;
  padding: 8px 0;
  font-size: 1.2rem;
  border-bottom: 1px solid #808080;
`
const TitleTbody = styled.td`
  color: #000000;
  font-size: 1rem;
  font-weight: bold;
  padding: 4px 16px;
`
const Tbody = styled.td`
  padding: 4px 16px;
  font-size: 1rem;
  text-align: right;
`

export default StatusTable
