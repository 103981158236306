import React from 'react'
import styled from 'styled-components'
import stafflist from 'images/home/feature/01_staff.png'
import managementRequestlist from 'images/home/feature/02_request_list.png'
import csv from 'images/home/feature/03_csv.png'
import staffRequestlist from 'images/home/feature/05_requests.png'
import timestamp from 'images/home/feature/04_timestamp.png'
import attendance from 'images/home/feature/06_attendance.png'

export const Feature = () => {
  return (
    <FeatureArea>
      <Title>機能一覧</Title>
      <SubTitle>Functions</SubTitle>
      <List>
        <Item>
          <Content>
            <ImgArea>
              <Img src={stafflist} />
            </ImgArea>
            <TextArea>
              <ContentTitle>従業員管理機能</ContentTitle>
              <ContentText>
                全職種の社員を一覧で登録することができます。部署や管轄、所属店舗等のグループ登録や、社員種別（正社員、契約社員、業務委託等）も社内カスタマイズ登録できるので、
                一目で社員の属性が把握できます。
              </ContentText>
            </TextArea>
          </Content>
        </Item>
        <Item>
          <Content>
            <ImgArea>
              <Img src={managementRequestlist} />
            </ImgArea>
            <TextArea>
              <ContentTitle>各種申請管理機能</ContentTitle>
              <ContentText>
                全社員から提出された休暇申請、休日出勤申請といった各種申請を一覧で登録することができます。申請書を印刷して、手書きで記入し、捺印して提出といったアナログ業務フローから脱却でき、
                管理者、社員双方の業務効率改善をサポートします。
              </ContentText>
            </TextArea>
          </Content>
        </Item>
        <Item>
          <Content>
            <ImgArea>
              <Img src={csv} />
            </ImgArea>
            <TextArea>
              <ContentTitle>CSVダウンロード機能</ContentTitle>
              <ContentText>
                全社員の勤怠情報は自動集計され、システム上で表示されますが、さらに詳細に集計したい。個別にまとめたい等の作業用に指定した条件での勤怠情報のデータ出力が
                CSV形式で可能です。
              </ContentText>
            </TextArea>
          </Content>
        </Item>
        <Item>
          <Content>
            <ImgArea>
              <Img src={timestamp} />
            </ImgArea>
            <TextArea>
              <ContentTitle>打刻機能</ContentTitle>
              <ContentText>
                ワンクリックで出勤→退勤ができ、日報やTODOメモも同時に記入登録することができます。また、退勤の押し忘れ等があっても打刻の修正ができますので、ご安心ください。
              </ContentText>
            </TextArea>
          </Content>
        </Item>
        <Item>
          <Content>
            <ImgArea>
              <Img src={staffRequestlist} />
            </ImgArea>
            <TextArea>
              <ContentTitle>各種申請機能</ContentTitle>
              <ContentText>
                休暇申請、残業申請、休日出勤申請等を最低限の情報入力で管理者に対してシステム上で申請提出ができます。
                申請後、リアルタイムに管理者側に申請が届くので、承認待ちへの時間も短縮されます。
              </ContentText>
            </TextArea>
          </Content>
        </Item>
        <Item>
          <Content>
            <ImgArea>
              <Img src={attendance} />
            </ImgArea>
            <TextArea>
              <ContentTitle>勤怠表機能</ContentTitle>
              <ContentText>
                月別に出勤時間、退勤時間、日毎のメモ、日報が閲覧・修正ができます。
                出勤時や退勤時に記録し忘れても、後日記録できるので、安心です。
              </ContentText>
            </TextArea>
          </Content>
        </Item>
      </List>
    </FeatureArea>
  )
}

const FeatureArea = styled.div`
  background: linear-gradient(90deg, rgba(31, 49, 113, 1), rgba(76, 162, 213, 1));
  padding: 4rem 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`
const Title = styled.div`
  color: #ffffff;
  font-size: 2.5rem;
  font-weight: bold;
  text-align: center;
`
const SubTitle = styled.div`
  color: #c3c3c3;
  font-size: 2rem;
  font-weight: bold;
  font-family: 'Michroma', sans-serif, 'Noto Sans JP';
  text-align: center;
  margin-bottom: 4rem;
`
const List = styled.div`
  width: 95%;
  background-color: #ffffff;
  border-radius: 1rem;
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
`
const Item = styled.div`
  grid-column: span 1 / span 1;
  padding: 1rem;
`
const Content = styled.div`
  width: 100%;
  display: flex;
  margin-top: 2rem;
  @media screen and (max-width: 1000px) {
    flex-direction: column;
  }
`
const ContentTitle = styled.div`
  font-weight: bold;
  font-size: 1.5rem;
  border-bottom: 1px solid gray;
  text-align: left;
`
const ContentText = styled.p`
  text-align: left;
`
const ImgArea = styled.div`
  width: 50%;
  @media screen and (max-width: 1000px) {
    width: 100%;
  }
`
const Img = styled.img`
  width: 100%;
  height: auto;
`
const TextArea = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  padding: 0 1rem;
  @media screen and (max-width: 1000px) {
    width: 100%;
  }
`

export default Feature
