import React from 'react';
import styled from 'styled-components'

export const Info: React.FC = ({ children }) => {
  return (
    <InfoMessage>
      {children}
    </InfoMessage>
  )
}

const InfoMessage = styled.p`
  color: #06d6a0;
  font-weight: bold;
  padding: 10px 0;
`

export default Info
