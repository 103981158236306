import React from 'react';
import styled from 'styled-components'
import Timestamp from '../../organisms/employees/Timestamp'

export const Index: React.FC = () => {
  return (
    <IndexArea>
      <Timestamp />
    </IndexArea>
  )
}

const IndexArea = styled.div`
  border-radius:  1rem;
  box-shadow: 0 0 1rem rgba(0,0,0,0.3);
  padding: 0 3rem;
`

export default Index
