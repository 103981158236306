import React from 'react'
import styled from 'styled-components'

export interface CheckBoxProps {
  text: string;
  onChange: any;
}

export const TextForm: React.FC<CheckBoxProps> = ({ text, onChange }) => {
  return (
    <CheckBoxArea>
      <Input type='checkbox' value='true' onChange={onChange} />
      <Label>{text}</Label>
    </CheckBoxArea>
  )
}

const CheckBoxArea = styled.div`
  display: flex;
  font-weight: bold;
`
const Label = styled.label`
  margin-left: 10px;
`
const Input = styled.input`
  width: 20px;
  height: 20px;
  margin: auto;
`

export default TextForm
