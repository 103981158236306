export const hours = (allowZero : boolean = false) => {
  const start = allowZero? 0 : 1
  const arr = []
  for (let i = start; i <= 24; i++) {
    arr.push({ value: i, label: i })
  }
  return arr
}



export const minutes = []
for (let i = 0; i <= 59; i++) {
  minutes.push({ value: i, label: i })
}
