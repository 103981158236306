import React from 'react'
import styled from 'styled-components'
import SignupButton from '../../atoms/SignupButton'
import LoginButton from '../../atoms/LoginButton'

export const Buttons = () => {
  return (
    <ButtonsArea>
      <Button>
        <SignupButton />
      </Button>
      <Button>
        <LoginButton />
      </Button>
    </ButtonsArea>
  )
}

const ButtonsArea = styled.div`
  display: flex;
  justify-content: space-around;
  margin: 0 auto 50px;
  padding: 50px 300px;
`
const Button = styled.div`
  width: 300px;
`

export default Buttons
