import client from './client'

export const fetchTimestamps = (id?: number, year?: number, month?: number) => {
  return client.get('/api/v1/timestamps', { params: { id: id, year: year, month: month } })
}

export const fetchTimestamp = () => {
  return client.get('/api/v1/timestamps/today')
}

// ここでフロントから打刻時間を送るか悩みどころ
export const createTimestamp = (note: string) => {
  return client.post('/api/v1/timestamps', { note: note })
}
export const deleteTimestamp = (id: number) => {
  return client.delete(`/api/v1/timestamps/${id}`)
}
