import React from 'react';
import styled from 'styled-components'
import SideLink from '../../molecules/share/SideLink'
import { signOut } from '../../../api/user'
import LogoEmployee from '../../molecules/share/LogoEmployee';

const handleSignOut = () => {
  signOut()
    .then(() => {
      document.location.href = '/'
    })
    .catch((res) => {
      window.alert(res.response.data.message)
    })
}

export const SideList = () => {
  return (
    <SideListArea>
      <LogoArea>
        <LogoEmployee />
      </LogoArea>
      <List>
        <SideLink title='打刻' path='/employees' />
        <SideLink title='出勤表' path='/employees/attendance' />
        <SideLink title='打刻修正' path='/employees/modify' />
        <SideLink title='申請' path='/employees/requests' />
        <SideLink title='登録情報' path='/employees/settings' />
        <Logout onClick={handleSignOut}>ログアウト</Logout>
      </List>
    </SideListArea>
  )
}

const LogoArea = styled.div`
  margin-top: 40px;
`
const SideListArea = styled.div`
  background: linear-gradient(to right, #effde3 0%, #ccebe9 100%);
  min-height: 93vh;
  height: 100%;
  width: 15vw;
  padding: 10px 20px;
  box-sizing: border-box;
  font-weight: bold;
`
const List = styled.div`
  margin-top: 75px;
  color: #000000;
  padding: 1rem 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`
const Logout = styled.div`
  cursor: pointer;
`

export default SideList
