import React from 'react';
import styled from 'styled-components'

export interface Props {
  path: string;
  text: string;
}

export const ButtonSub: React.FC<Props> = ({ path, text }) => {
  return <ButtonArea href={path}>{text}</ButtonArea>
}

const ButtonArea = styled.a`
  display: block;
  color: #ffffff;
  background: linear-gradient(to right,#ffffff 50%, #9BA4B4 50%);
  background-size: 200% 100.5%;
  background-position: right bottom;
  transition: all .3s ease-out;
  border: 1px solid #9BA4B4;
  border-radius: 6px;
  box-sizing: border-box;
  padding: 5px 15px;
  text-decoration: none;
  text-align: center;
  width: 5rem;
  white-space: nowrap;
  &:hover {
    color: #9BA4B4;
    background-position: left bottom;
  }
`

export default ButtonSub
