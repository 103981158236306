import client from './client'

export const fetchEmploymentStatuses = () => {
  return client.get('/api/v1/employment_statuses')
}

export const createEmploymentStatus = (name: string) => {
  return client.post('/api/v1/employment_statuses', { name: name })
}

export const updateEmploymentStatus = (id: number, name: string) => {
  return client.put(`/api/v1/employment_statuses/${id}`, { name: name })
}

export const deleteEmploymentStatus = (id: number) => {
  return client.delete(`/api/v1/employment_statuses/${id}`)
}
