import React, { ReactNode } from 'react';
import styled from 'styled-components'

export interface Props {
  children: ReactNode
}

export const TableBodyLarge: React.FC<Props> = ({ children }) => {
  return (
    <BodyArea>{children}</BodyArea>
  )
}

const BodyArea = styled.td`
  box-sizing: border-box;
  font-size: 14px;
  padding: 13px 5px;
  min-width: 15rem;
`

export default TableBodyLarge
