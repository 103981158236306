import React from 'react';
import styled from 'styled-components'

export interface Props {
  path: string;
  text: string;
}

export const LinkText: React.FC<Props> = ({ path, text }) => {
  return <TextArea href={path}>{text}</TextArea>
}

const TextArea = styled.a`
  color: #707071;
`

export default LinkText
