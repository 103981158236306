import React from 'react';
import styled from 'styled-components'
import Title from '../../atoms/share/Title'
import HolidayRequestList from '../../organisms/employees/requests/HolidayRequestList'
import HolidayWorkRequestList from '../../organisms/employees/requests/HolidayWorkRequestList'
import OverWorkRequestList from '../../organisms/employees/requests/OverWorkRequestList'


export const Requests = () => {
  return (
    <IndexArea>
      <RequestArea>
        <TitleArea>
          <Title value='休暇申請一覧' />
        </TitleArea>
        <HolidayRequestList />
      </RequestArea>
      <RequestArea>
        <TitleArea>
          <Title value='残業申請一覧' />
        </TitleArea>
        <OverWorkRequestList />
      </RequestArea>
      <RequestArea>
        <TitleArea>
          <Title value='休日出勤申請一覧' />
        </TitleArea>
        <HolidayWorkRequestList />
      </RequestArea>
    </IndexArea>
  )
}

const IndexArea = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 3rem;
`
const RequestArea = styled.div`
  background-color: #ffffff;
  padding: 2rem;
  border-radius: 1rem;
`
const TitleArea = styled.div`
    width: 100%;
    display: flex;
    margin-bottom: 1rem;
`

export default Requests
