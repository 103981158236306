import React, { ReactNode } from 'react';
import styled from 'styled-components'

export interface Props {
  children: ReactNode
}

export const TableButtonArea: React.FC<Props> = ({ children }) => {
  return (
    <BodyArea>{children}</BodyArea>
  )
}

const BodyArea = styled.td`
  display: flex;
  column-gap: 1rem;
  justify-content: right;
`

export default TableButtonArea
