import client from './client'

export const createWorkingTime = (fixedHour: number, fixedMinute: number, overHour: number, overMinute: number, unpaidHour?: number, unpaidMinute?: number, departmentId?: number, employmentStatusId?: number) => {
  return client.post('/api/v1/working_times', {
    fixed_hour: fixedHour,
    fixed_minute: fixedMinute,
    over_hour: overHour,
    over_minute: overMinute,
    unpaid_hour: unpaidHour,
    unpaid_minute: unpaidMinute,
    department_id: departmentId,
    employment_status_id: employmentStatusId
  })
}
