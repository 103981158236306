import React from 'react'
import styled from 'styled-components'

export const LoginButton = () => {
  return (
    <Login href='/companies/sign_in'>
      サインイン
    </Login>
  )
}

const Login = styled.a`
  color: #ffffff;
  text-decoration: none;
  background: linear-gradient(90deg, #37a179 0%, #0c3d7e 100%);
  border-radius: 2.7rem;
  padding: 0.3rem 1rem;
  cursor: pointer;
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
  letter-spacing: 0.1rem;
  &:hover {
    color: #ece9e9e6;
  }
`

export default LoginButton
