import React, { useState, useEffect } from 'react';
import styled from 'styled-components'
import TableHead from '../../atoms/share/TableHead'
import TableBody from '../../atoms/share/TableBody'
import SecondaryButton from '../../atoms/share/SecondaryButton'
import { fetchModifyTimestamps } from '../../../api/modify_timestamp'

const heads = ['申請日時', '修正日', '修正前出勤時刻', '修正後出勤時刻', '修正前退勤時刻', '修正後退勤時刻', '修正理由']

const status = (approved: boolean) => {
  if (approved == true) {
    return '承認済み'
  } else {
    return '承認待ち'
  }
}

export const ModifyTimestampList = () => {
  const [modifyTimestamps, setModifyTimestamps] = useState([])
  useEffect(() => {
    fetchModifyTimestamps()
      .then((res) => {
        setModifyTimestamps(res.data)
      })
      .catch(() => {
        window.alert('通信に失敗しました')
      })
  }, []);

  return (
    <ModifyTimestampListArea>
      <Table>
        <TableHead items={heads} />
        <Tbody>
          {modifyTimestamps.map((modifyTimestamp, index) => (
            <Row key={index}>
              <TableBody>{modifyTimestamp.created_at}</TableBody>
              <TableBody>{modifyTimestamp.modify_date}</TableBody>
              <TableBody>{modifyTimestamp.before_punch_in_at}</TableBody>
              <TableBody>{modifyTimestamp.after_punch_in_at}</TableBody>
              <TableBody>{modifyTimestamp.before_punch_out_at}</TableBody>
              <TableBody>{modifyTimestamp.after_punch_out_at}</TableBody>
              <TableBody>{modifyTimestamp.reason}</TableBody>
            </Row>
          ))}
        </Tbody>
      </Table>
    </ModifyTimestampListArea>
  )
}

const ModifyTimestampListArea = styled.div`
  font-size: 16px;
`
const Table = styled.table`
  border-collapse: collapse;
  width: 80%;
  table-layout: fixed;
`
const Tbody = styled.tbody`
  display: contents;
  overflow-x: hidden;
  overflow-y: scroll;
  height: 300px;
`
const Row = styled.tr`
`

export default ModifyTimestampList
