import React from 'react'
import styled from 'styled-components'
import summary1 from 'images/home/summary/1.jpg'
import summary2 from 'images/home/summary/2.jpg'
import summary3 from 'images/home/summary/3.jpg'

export const Summary = () => {
  return (
    <SummaryArea>
      <Title>スマート勤怠 EXtができること</Title>
      <SubTitle>What we do</SubTitle>
      <List>
        <Item>
          <Subheading>勤怠情報DX</Subheading>
          <IconArea>
            <Img src={summary1} />
          </IconArea>
          <TextArea>
            管理者アカウントと社員アカウントが分かれており、<br />
            各社員はそれぞれのアカウントからシンプルな打刻入力で、出退勤を登録できます。<br />
            アナログ管理時代は毎月末に集計作業を要していましたが、システム上で自動集計されるため、
            大幅に業務コスト削減ができます。
          </TextArea>
        </Item>
        <Item>
          <Subheading>シンプルな各種勤怠申請</Subheading>
          <IconArea>
            <Img src={summary2} />
          </IconArea>
          <TextArea>
            各社員はそれぞれのアカウントから休暇、残業、休日出勤申請が可能です。<br />
            申請すると管理者は各種申請一覧からワンクリックで承認・却下対応ができます。<br />
            申請書類の手続き及び管理がシステム上で一元化され、大幅に業務コスト削減ができます。
          </TextArea>
        </Item>
        <Item>
          <Subheading>登録情報はCSV形式でエクスポート可能</Subheading>
          <IconArea>
            <Img src={summary3} />
          </IconArea>
          <TextArea>
            「スマート勤怠 EXt」は使いやすさ、情報のシンプルさを追求しています。<br />
            より詳細に管理するフローがある場合に対して、システムに登録された情報を
            CSV形式で出力することが可能です。
          </TextArea>
        </Item>
      </List>
    </SummaryArea>
  )
}

const SummaryArea = styled.div`
  background: #ffffff;
  padding-bottom: 3rem;
  width: 100%;
`
const List = styled.div`
  width: 95%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 1rem;
`
const Item = styled.div`
  grid-column: span 1 / span 1;
  background: #ffffff;
  border-radius: 1rem;
  box-shadow: 0 0 1rem  rgba(0,0,0,0.3);
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`
const Img = styled.img`
  margin: auto;
  width: auto;
  height: 100%;
  @media screen and (max-width: 1000px) {
    width: 100%;
    height: auto;
  }
`
const IconArea = styled.div`
  height: 250px;
  margin-bottom: 1rem;
  @media screen and (max-width: 1000px) {
    height:  10rem;
    margin-bottom: 0;
  }
`
const TextArea = styled.div`
  width: 80%;
  @media screen and (max-width: 1000px) {
    width: 100%;
}
`
const Subheading = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 1rem;
  @media screen and (max-width: 1000px) {
    height: 3rem;
  }
`
const Title = styled.div`
  color: #000000;
  font-size: 2.5rem;
  font-weight: bold;
  text-align: center;
`
const SubTitle = styled.div`
  color: #c3c3c3;
  font-size: 2rem;
  font-weight: bold;
  font-family: 'Michroma', sans-serif, 'Noto Sans JP';
  text-align: center;
  margin-bottom: 4rem;
`
export default Summary
